<template>
  <div class="passflow">
    <div class="data">{{ data }}</div>
    <div class="daimg">
      <div class="imgitem" v-for="(item,index) in strList" :key="index" >
        <div class="list-item">
          <div class="srCimg"> <img :src="item.picUrl" alt=""> </div>
           <div class="dateitem">
             <span v-if="item.time <= 60000">刚刚</span>
             <span v-if="item.time > 60000 && item.time < 3600000">{{ Math.floor(item.time/60000) }} 分钟前 </span>
             <span v-if="item.time >= 3600000">{{ Math.floor(item.time/3600000) }} 小时前 </span>
             <span>{{item.gender === 1? '女':'男'}}</span>
            <span >年龄:{{item.age}}</span>
            <span v-if="item.exit === 0">生客</span>
            <span v-if="item.exit === 1">熟客</span>
            <span v-if="item.exit === -1">未知</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WebSocket',
  components: {},
  data () {
    return {
      data:'',
      srcImg:[],
      strList:[],
      websocket: null
    }
  },
  mounted () {
    if ('WebSocket' in window) {
       this.websocket = new WebSocket('ws://192.168.2.170:26500/ws/')
      this.initWebSocket()
    } else {
      alert('当前浏览器 Not support websocket')
    }
  },
  beforeDestroy () {
    this.onbeforeunload()
  },
  methods: {
    initWebSocket () {
      //连接错误
      this.websocket.onerror = this.setErrorMessage

      // //连接成功
      this.websocket.onopen = this.setOnopenMessage

      //收到消息的回调
      this.websocket.onmessage = this.setOnmessageMessage

      //连接关闭的回调
      this.websocket.onclose = this.setOncloseMessage

      //监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
      window.onbeforeunload = this.onbeforeunload
    },
    setErrorMessage () {
      this.websocket.readyState
    },
    setOnopenMessage () {
      this.websocket.readyState
    },
    setOnmessageMessage (event) {
         this.srcImg.push(JSON.parse(event.data))
        // console.log(this.srcImg,'this.srcImg')
          let total = []
            for (let item of this.srcImg) {
              let th = item.photoTime
              let timeNew = Date.parse(new Date()); //当前时间
              let timeValue = Date.parse(th)
              let timeDiffer = timeNew - timeValue; //与当前时间误差
              let url = item.picUrl
              let gend = item.gender
              let age = item.age
              let exit = item.faceLibIsExit
              let strObj = {}
                strObj.picUrl = url
                strObj.gender = gend
                strObj.age = age
                strObj.exit = exit
                strObj.time = timeDiffer
                total.push(strObj)
                total.sort((a, b) => a.time - b.time).splice(100)
            }
            this.strList = total
            console.log(this.strList,'this.strList')
        // console.log(Object.values(dataEvent).sort((a, b) => b.photoTime - a.photoTime),'this.srcImg')
      },
    setOncloseMessage () {
      this.websocket.readyState
    },
    onbeforeunload () {
      this.closeWebSocket()
    },
    //websocket发送消息
    // send () {
    //   this.websocket.send('')
    // },
    closeWebSocket () {
      this.websocket.close()
    }
  }
}
</script>
<style lang="scss" scoped>
.passflow{
  .daimg{
      height: 515px;
      overflow-y: scroll;
      .imgitem{
        display: inline-block;
        overflow-x: hidden;
        padding: 0 20px;
        .list-item{
           .srCimg{
              width: 185px;
              height: 150px;
              img{
              width: 100%;
              height: 100%;
              }
           }
        }
      }
      .dateitem{
        font-size: 14px;
        text-align: center;
        color: #ccc;
        span{
        line-height: 35px;
        padding-right: 5px;
        }
      } 
  }
  .daimg::-webkit-scrollbar {
    display: none;
  }
}
</style>