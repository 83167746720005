<template>
  <div id="monitor" ref="monitor">
    <div id="heatmap" class="monitor_img listbox">
      <img src="../../../assets/pmt.png" alt="商场平面图" ref="monitorimg" />
      <!-- <div> -->
      <li
        v-for="(items, indexs) in listmapall"
        :key="indexs"
        ref="monitorlist"
        @mousedown.self="move"
        :id="items.id"
        :style="{
          left: JSON.parse(items.mapLocation).x * monitorimgwith - 20 + 'px',
          top: JSON.parse(items.mapLocation).y * monitorimgheight - 60 + 'px',
        }"
      >
        <span class="deviceid">
          {{ items.name }}
        </span>
        <div :id="items.id">{{ items.num }}</div>
        <div></div>
      </li>
      <!-- </div> -->
      <!-- <div>
        <el-button type="primary" @click="peoplebtn(1000, 0)"
          >1000以下</el-button
        >
        <el-button type="primary" @click="peoplebtn(2000, 1000)"
          >1000~2000</el-button
        >
        <el-button type="primary" @click="peoplebtn(5000, 2000)"
          >2000~5000</el-button
        >
      </div> -->
    </div>
    <div class="cantainer">
      <div class="monitorlist">
        <div class="listbox" ref="listbox">
          <div
            class="listboxdiv"
            v-for="(item, index) in listall.slice(
              (currentPage - 1) * pagesize,
              currentPage * pagesize
            )"
            :key="index"
          >
            <li
              ref="monitorlist"
              @mousedown.self="move"
              :id="item.id"
              :style="{
                left: positionx + 'px',
                top: positiony + 'px',
              }"
            >
              <span class="deviceid">
                {{ item.name }}
              </span>
              <div style="display: none">{{ item.id }}</div>
            </li>
          </div>
          <!-- <li
        v-for="(item, index) in list" :key="index"
            ref="monitorlist"
            @mousedown.self="move"
            :id="index"
            :style="{ left:JSON.parse(item.mapLocation).x + 'px', top: JSON.parse(item.mapLocation).y + 'px' }"
          >
            <div style="display: none">{{ item.people }}</div>
          </li> -->
        </div>
        <div class="monitor_btn">
          <el-button type="primary" @click="choices">{{
            !choice ? "调整" : "保存"
          }}</el-button>
        </div>
        <el-pagination
          v-if="listall.length > pagesize"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="listall.length"
          style="text-align: center; margin-top: 10px"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import heatmap from "heatmap.js"; //引入heatmap.js
export default {
  data() {
    return {
      choice: false,
      choicenum: false,
      currentPage: 1, //初始页
      pagesize: 20, //    每页的数据
      list: [],
      positionx: 0,
      positiony: 0,
      listmon: [],
      listmapalls: [],
      peoplenum: {
        max: "",
        min: "",
        timer: null,
      },
      monitorimgwith: "",
      monitorimgheight: "",
    };
  },
  mounted() {
    this.getdevicelist();
    let that = this;
    setInterval(() => {
      that.getdevicelist();
    }, 5000);
    // clearInterval(this.timer);
    // this.timer = null;
    var maxpeople = [];
    for (var i = 0; i < this.list.length; i++) {
      maxpeople.push(this.list[i].people);
    }
    this.peoplenum.max = Math.max(...maxpeople);
    this.peoplenum.min = Math.min(...maxpeople);
  },
  methods: {
    // 调整显示的条数
    handleSizeChange: function (size) {
      this.pagesize = size;
      // console.log(this.pagesize);
    },
    // 翻页
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
      // console.log(this.currentPage);
    },
    // 拖动摄像头
    // movemon(e) {
    //   var monlist = e.target; //获取目标元素
    //   var disX = e.clientX - monlist.offsetLeft;
    //   var disY = e.clientY - monlist.offsetTop;
    //   if (this.choice) {
    //     // console.log(monlist);
    //     // monlist.lastChild.style.display = "none";
    //     //算出鼠标相对元素的位置
    //     // 限制范围
    //     let maxtop = this.$refs.monitor.getBoundingClientRect().top;
    //     let maxleft = this.$refs.monitor.getBoundingClientRect().left;
    //     let mintop = this.$refs.monitor.getBoundingClientRect().bottom;
    //     let mintleft = this.$refs.monitor.getBoundingClientRect().right;
    //     // 判断监控范围
    //     let monmintop = this.$refs.monitorimg.getBoundingClientRect().bottom;

    //     let monitorimgwith = this.$refs.monitorimg.offsetWidth;
    //     let monitorimgheight = this.$refs.monitorimg.offsetHeight;
    //     // console.log(minmaxtop)
    //     document.onmousemove = (e) => {
    //       //鼠标按下并移动的事件
    //       //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
    //       let maxtops = e.clientY;
    //       let maxlefts = e.clientX;
    //       let left = e.clientX - disX;
    //       let top = e.clientY - disY;
    //       if (maxtop > maxtops) {
    //         top = maxtop - disY + monlist.offsetHeight;
    //       }
    //       if (maxleft > maxlefts) {
    //         left = maxleft - disX + monlist.offsetHeight;
    //       }

    //       if (maxtops > mintop) {
    //         top = mintop - disY - monlist.offsetHeight;
    //       }
    //       if (maxlefts > mintleft) {
    //         left = mintleft - disX - monlist.offsetHeight;
    //       }

    //       //移动当前元素
    //       let arr = this.listmon;
    //       arr.filter((items, index) => {
    //         if (items.id == monlist.lastChild.id) {
    //           JSON.parse(arr[index].mapLocation).x = left;
    //           JSON.parse(arr[index].mapLocation).y = top;
    //         }
    //       });
    //       // monlist.style.left = left + "px";
    //       // monlist.style.top = top + "px";
    //     };
    //     document.onmouseup = (e) => {
    //       if (monmintop < e.clientY + monlist.offsetHeight / 2) {
    //         // console.log("在外面");
    //         let arr = this.listmon;
    //         const map = new Map();
    //         arr.filter((item, index) => {
    //           if (item.id == monlist.lastChild.id) {
    //             (JSON.parse(arr[index].mapLocation).x = 0),
    //               (JSON.parse(arr[index].mapLocation).y = 0),
    //               this.list.push(arr[index]);
    //             this.list = this.unique(this.list);
    //             arr.splice(index, 1);
    //           }
    //         });
    //         this.list.sort(function (a, b) {
    //           return a.id > b.id ? 1 : -1;
    //         });
    //       } else {
    //         let x = (e.clientX - (maxleft + 20)) / monitorimgwith;
    //         let y = (e.clientY - (maxtop + 20)) / monitorimgheight;
    //         this.mapLocation = {
    //           x: x,
    //           y: y,
    //         };
    //         // console.log(this.mapLocation);
    //       }
    //       document.onmousemove = null;
    //       document.onmouseup = null;
    //     };
    //   }
    //   // else {
    //   //   // console.log(e.clientY);
    //   //   if (
    //   //     this.choicenum &&
    //   //     monlist.style.top != 0 + "px" &&
    //   //     e.clientY < 615
    //   //   ) {
    //   //     let dis_sty = monlist.lastChild.style.display;
    //   //     if (dis_sty == "none") {
    //   //       monlist.lastChild.style.display = "block";
    //   //     } else {
    //   //       monlist.lastChild.style.display = "none";
    //   //     }
    //   //   }
    //   // }
    // },
    // 拖动列表摄像头
    move(e) {
      var monlist = e.target; //获取目标元素
      var disX = e.clientX - monlist.offsetLeft;
      var disY = e.clientY - monlist.offsetTop;
      if (this.choice) {
        monlist.lastChild.style.display = "none";
        // console.log(monlist);
        //算出鼠标相对元素的位置
        // 限制范围
        let maxtop = this.$refs.monitor.getBoundingClientRect().top;
        let maxleft = this.$refs.monitor.getBoundingClientRect().left;
        let mintop = this.$refs.monitor.getBoundingClientRect().bottom;
        let mintleft = this.$refs.monitor.getBoundingClientRect().right;
        // 判断监控范围
        let monmintop = this.$refs.monitorimg.getBoundingClientRect().bottom;
        document.onmousemove = (e) => {
          // console.log(e)
          //鼠标按下并移动的事件
          //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
          let maxtops = e.clientY;
          let maxlefts = e.clientX;
          let left = e.clientX - disX;
          let top = e.clientY - disY;
          // console.log(left)
          if (maxtop > maxtops) {
            top = maxtop - disY + monlist.offsetHeight;
          }
          if (maxleft > maxlefts) {
            left = maxleft - disX + monlist.offsetHeight;
          }

          if (maxtops > mintop) {
            top = mintop - disY - monlist.offsetHeight;
          }
          if (maxlefts > mintleft) {
            left = mintleft - disX - monlist.offsetHeight;
          }

          //移动当前元素
          monlist.style.left = left + "px";
          monlist.style.top = top + "px";
        };
        document.onmouseup = (e) => {
          // console.log(e);
          // console.log(disY);

          if (monmintop < e.clientY + monlist.offsetHeight / 2) {
            if (disY > monmintop) {
              monlist.style.left = 0 + "px";
              monlist.style.top = 0 + "px";
            }
            let mapxy = {
              deviceId: monlist.id,
              x: 0,
              y: 0,
            };
            let arr = this.listmon;
            arr.push(mapxy);
            arr.filter((items, index) => {
              if (items.deviceId == monlist.id) {
                // id相等换了x,y值
                return (arr[index].x = 0), (arr[index].y = 0);
              }
            });
          } else {
            monlist.style.left = e.clientX - (maxleft + 20);
            monlist.style.top = e.clientY - (maxtop + 20);
            let x = (e.clientX - (maxleft + 20)) / this.monitorimgwith;
            let y = (e.clientY - (maxtop + 20)) / this.monitorimgheight;
            let mapxy = {
              deviceId: monlist.id,
              x: x,
              y: y,
            };
            let arr = this.listmon;
            arr.push(mapxy);
            arr.filter((items, index) => {
              if (items.deviceId == monlist.id) {
                // id相等换了x,y值
                return (arr[index].x = x), (arr[index].y = y);
              }
            });
            arr = this.unique(arr);
          }
          document.onmousemove = null;
          document.onmouseup = null;
        };
      } else {
        let monmintop = this.$refs.monitorimg.getBoundingClientRect().bottom;
        if (
          this.choicenum &&
          monlist.style.top != 0 + "px" &&
          e.clientY < monmintop
        ) {
          let dis_sty = monlist.lastChild.style.display;
          if (dis_sty == "none") {
            monlist.lastChild.style.display = "block";
          } else {
            monlist.lastChild.style.display = "none";
          }
        }
      }
    },
    // 点击编辑/保存
    choices(e) {
      let btnname = e.target.innerText;
      this.choice = !this.choice;
      this.choicenum = false;
      let that = this;
      if (btnname == "保存") {
        this.getmapLocations();
        this.choicenum = true;
        // that.heatmap();
        // if (this.listmon != "") {
        //   this.timer = setInterval(function () {
        //     that.heatmap();
        //   }, 5000);
        // }
      } else if (btnname == "调整" || this.listmon == "") {
        // clearInterval(this.timer);
        // this.timer = null;
      }
    },
    // 数据去重
    unique(arr) {
      const map = new Map();
      return arr.filter(
        (arr) => !map.has(arr.deviceId) && map.set(arr.deviceId, 1)
      );
    },
    listunique(arr) {
      const map = new Map();
      return arr.filter(
        (arr) =>
          !map.has(arr.id) && map.set(arr.id, 1) && arr.mapLocation != null
      );
    },
    // 点击人流量大小
    peoplebtn(max, min) {
      if (this.listmon != "") {
        // console.log(666);
        this.peoplenum = {
          max: max,
          min: min,
        };
        this.heatmap();
      }
    },
    // 生成热力图
    heatmap() {
      var delheatmap = document.getElementById("heatmap");
      var delcanvas = document.getElementsByClassName("heatmap-canvas");
      if (delcanvas.length > 0) {
        delheatmap.removeChild(delcanvas[0]);
      }
      var heatmapInstance = heatmap.create({
        container: document.querySelector("#heatmap"),
        radius: 120,
        maxOpacity: 0.5,
        minOpacity: 0,
        blur: 0.75,
      });

      var points = [];
      var len = this.listmapall.length;
      var i = 0;
      var listmons = this.listmapall;
      var val = Math.floor(Math.random() * 10000);
      console.log(JSON.parse(listmons[i].mapLocation).x * this.monitorimgwith);
      while (len--) {
        var point = {
          x: parseInt(
            JSON.parse(listmons[i].mapLocation).x * this.monitorimgwith,
            10
          ),
          y: parseInt(
            JSON.parse(listmons[i].mapLocation).y * this.monitorimgheight - 40,
            10
          ),
          value: listmons[i].num,
        };
        i++;
        console.log(point);
        points.push(point);
      }

      var data = {
        max: 200,
        min: 0,
        data: points,
      };

      heatmapInstance.setData(data);
    },
    // 获取设备列表
    sortNumber(a, b) {
      return a - b;
    },
    getdevicelist() {
      // this.list = [];
      let data = 1;
      this.$api.monitor.devicelist("camera-group1").then((res) => {
        // console.log(res)
        if (res != "") {
          this.list = this.listunique(res);
          // console.log(this.list)
        }
        this.monitorimgwith = this.$refs.monitorimg.offsetWidth;
        this.monitorimgheight = this.$refs.monitorimg.offsetHeight;
        this.getphoto();
      });
    },
    // 保存坐标信息
    getmapLocations() {
      this.$api.monitor.getmapLocation(this.listmon).then((res) => {
        this.getdevicelist();
      });
    },
    // 获取拍照人数
    getphoto() {
      this.$api.monitor.getphotos().then((res) => {
        // console.log(res)
        for (var i = 0; i < this.list.length; i++) {
          for (var j = 0; j < res.length; j++) {
            if (this.list[i].id == res[j].deviceId) {
              let data = { num: res[j].num };
              //    this.listmapall[i].assign(data)
              Object.assign(this.list[i], data);
            }
          }
          this.listmapalls = this.list;
        }
      });
    },
  },

  computed: {
    // 对list数据进行筛选
    listmapall: function () {
      return this.listmapalls.filter(
        (items) =>
          JSON.parse(items.mapLocation).x != 0 &&
          JSON.parse(items.mapLocation).y != 0
      );
    },
    listall: function () {
      return this.list.filter(
        (items) =>
          JSON.parse(items.mapLocation).x == 0 &&
          JSON.parse(items.mapLocation).y == 0
      );
    },
  },
};
</script>

<style  scoped>
li {
  list-style: none;
}
#monitor {
  padding: 8px 20px 0;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  flex-wrap: wrap;
}
.monitor_img {
  width: auto;
  background-image: url("../../../assets/pmt.png");
  background-size: 100% 100%;
  background-position: center;
}
.monitor_img img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  opacity: 0;
}

#heatmap > div button {
  padding: 5px;
}
.cantainer {
  width: 100%;
}
.monitorlist {
  border-radius: 10px;
  height: auto;
  min-height: 200px;
  background: linear-gradient(to bottom, #409eff, #55dbdf);
  margin: 20px auto 0;
  padding-bottom: 60px;
  box-sizing: border-box;
  position: relative;
}
.listbox {
  width: 100%;
  /* height: 100%; */
  max-height: 520px;
  position: relative;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}
.listbox > div.listboxdiv {
  position: relative;
  width: 40px;
  height: 40px;
  margin: 20px 22px 30px;
}
li {
  position: absolute;
  z-index: 5;
  width: 40px;
  /* margin: 10px; */
  height: 40px;
  display: inline-block;
  background: #666;
  cursor: move;
  list-style: none;
  border-radius: 50%;
  background-image: url("https://bpic.588ku.com/element_pic/20/07/01/c63a0d3180ccc3d5bbdfb257bc69e8f5.jpg!/fw/253/quality/90/unsharp/true/compress/true");
  background-size: 100% 100%;
  background-position: center;
}
li > div {
  margin: auto;
  position: relative;
  top: -60%;
  text-align: center;
  background: red;
  border-radius: 10px;
  color: #fff;
}
.monitor_btn {
  position: absolute;
  bottom: 30px;
  right: 30px;
}
.deviceid {
  width: 80px;
  text-align: center;
  display: inline-block;
  position: absolute;
  bottom: -66px;
  left: 50%;
  color: #000;
  /* background: #000; */
  /* opacity: .7; */
  border-radius: 5px;
  transform: translate(-50%, -50%);
}
</style>
