<template>
  <div id="monitor" ref="monitor">
    <div id="heatmap" class="monitor_img listbox">
      <img src="../../../assets/pmt.png" alt="商场平面图" ref="monitorimg" />

      <li
      v-for="(items, indexs) in listmapall"
      :key="indexs"
      ref="monitorlist"
      @mousedown.self="move"
      :id="items.id"
      :style="{
        left:
          JSON.parse(items.mapLocation).x * monitorimgwith -
          (monitorimgheight * 0.06)/2 +
          'px',
        top:
          JSON.parse(items.mapLocation).y * monitorimgheight -
          (monitorimgheight * 0.10) +
          'px',
        width: monitorimgheight * 0.07 + 'px',
        height: monitorimgheight * 0.07 + 'px',
      }"
    >
      <span class="deviceid">
        {{ items.name }}
      </span>
      <div :id="items.id">{{items.num}}</div>
    </li>
      <!-- <div>
        <el-button type="primary" @click="peoplebtn(1000, 0)"
          >1000以下</el-button
        >
        <el-button type="primary" @click="peoplebtn(2000, 1000)"
          >1000~2000</el-button
        >
        <el-button type="primary" @click="peoplebtn(5000, 2000)"
          >2000~5000</el-button
        >
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
       listall: [],
      monitorimgwith: "",
      monitorimgheight: "",
    };
  },
  mounted() {
    this.getdevicelist();
    let that = this;
    setInterval(() => {
      that.getdevicelist();
    }, 5000);
  },
  methods: {
    // 生成热力图
    // heatmap() {
    //   var delheatmap = document.getElementById("heatmap");
    //   var delcanvas = document.getElementsByClassName("heatmap-canvas");
    //   if (delcanvas.length > 0) {
    //     delheatmap.removeChild(delcanvas[0]);
    //   }
    //   var heatmapInstance = heatmap.create({
    //     container: document.querySelector("#heatmap"),
    //     radius: 120,
    //     maxOpacity: 0.5,
    //     minOpacity: 0,
    //     blur: 0.75,
    //   });

    //   var points = [];
    //   var len = this.listmon.length;
    //   var i = 0;
    //   var listmons = this.listmon;
    //   var val = Math.floor(Math.random() * 10000);
    //   while (len--) {
    //     var point = {
    //       x: listmons[i].positionX,
    //       y: listmons[i].positionY-10,
    //       value: listmons[i].people,
    //     };
    //     i++;
    //     // console.log(val)
    //     points.push(point);
    //   }

    //   var data = {
    //     max: this.peoplenum.max,
    //     min: this.peoplenum.min,
    //     data: points,
    //   };

    //   heatmapInstance.setData(data);
    // },
    // 获取设备列表
    getdevicelist() {
      this.$api.monitor.devicelist("camera-group1").then((res) => {
        // console.log(res);
        this.monitorimgwith = this.$refs.monitorimg.offsetWidth;
        this.monitorimgheight = this.$refs.monitorimg.offsetHeight;
          if (res != "") {
          this.list = this.listunique(res);
          // console.log(this.list)
        }
        this.getphoto()
        // console.log(this.listmapall)
      });
    },
      listunique(arr) {
      const map = new Map();
      return arr.filter(
        (arr) =>
          !map.has(arr.id) && map.set(arr.id, 1) && arr.mapLocation != null
      );
    },
    // 获取拍照人数
    getphoto(){
       this.$api.monitor.getphotos().then((res) => {
        // console.log(res)
        for(var i =0;i<this.list.length;i++){
          for(var j = 0;j<res.length;j++){
               if( this.list[i].id == res[j].deviceId){
                   let data = {"num":res[j].num}
            //    this.listmapall[i].assign(data)
            Object.assign(this.list[i],data)
           }
          }
          this.listall = this.list
        }
      });
    },
  },
  computed: {
    // 对list数据进行筛选
    listmapall: function () {
      return this.listall.filter(
        (items) =>
          JSON.parse(items.mapLocation).x != 0 &&
          JSON.parse(items.mapLocation).y != 0
      );
    },
  },
};
</script>

<style  scoped>
li {
  list-style: none;
}
#monitor {
  padding: 8px 20px 0;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  flex-wrap: wrap;
}
.monitor_img {
  width: auto;
  background-image: url("../../../assets/pmt.png");
  background-size: 100% 100%;
  background-position: center;
  position: relative;
}
.monitor_img img {
  width:auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  opacity: 0;
}

li {
  position: absolute;
  z-index: 5;
  display: inline-block;
  background: #666;
  cursor: move;
  list-style: none;
  border-radius: 50%;
  background-image: url("https://bpic.588ku.com/element_pic/20/07/01/c63a0d3180ccc3d5bbdfb257bc69e8f5.jpg!/fw/253/quality/90/unsharp/true/compress/true");
  background-size: 100% 100%;
  background-position: center;
}
li > div {
  margin: auto;
  position: relative;
  top: -80%;
  text-align: center;
  background: red;
  border-radius: 10px;
  color: #fff;
  margin-top: -5px;
  padding: 0 10px;
}
.deviceid {
  width: 80px;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 140%;
  left: 50%;
  color: #000;
  /* background: #000; */
  /* opacity: .7; */
  border-radius: 5px;
  transform: translate(-50%, -50%);
}
</style>
