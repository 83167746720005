<template>
  <!-- 时间段分析 -->
  <div class="chart-wrapper" style="width: 100%; height: 100%">
    <div class="chart" ref="chart" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
import echarts from 'echarts'
export default {
  data () {
    return {
      chart: null,
       arrNum : [],
      arrTime : []
    }
  },
  mounted () {
    this.initChart()
    let that = this;
    setInterval(() => {
       that.initChart();
    }, 10000);
  },
  methods: {
    initChart () {
      this.chart = echarts.init(this.$refs.chart)
       let arrNum = []
       let arrTime = []
      this.$api.charts.getTime().then(res => {
        for (let i = 0; i < res.length; i++) {
          arrNum.push(res[i].num)
          arrTime.push(res[i].hours)
        }
        this.chart.setOption({
          grid: {
            top: '4%',
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            axisLine: {
              lineStyle: {
                color: '#ccc'
              }
            },
            data: arrTime
            // [
            //   '0',
            //   '1',
            //   '2',
            //   '3',
            //   '4',
            //   '5',
            //   '6',
            //   '7',
            //   '8',
            //   '9',
            //   '10',
            //   '11',
            //   '12',
            //   '13',
            //   '14',
            //   '15',
            //   '16',
            //   '17',
            //   '18',
            //   '19',
            //   '20',
            //   '21',
            //   '22',
            //   '23'
            // ]
          },
          yAxis: {
            type: 'value',
            axisLine: {
              lineStyle: {
                color: '#ccc'
              }
            }
          },
          series: [
            {
              name: '客流',
              type: 'bar',
              barGap: '-100%',
              barWidth: 10,
              smooth: true,

              itemStyle: { barBorderRadius: 5, color: '#32E1BE' },
              data: arrNum
              // [
              //   50,
              //   20,
              //   36,
              //   10,
              //   10,
              //   20,
              //   5,
              //   20,
              //   36,
              //   10,
              //   10,
              //   20,
              //   5,
              //   20,
              //   36,
              //   10,
              //   10,
              //   20,
              //   5,
              //   20,
              //   36,
              //   10,
              //   10,
              //   20,
              // ],
            }
          ]
        })
      })
    },
    drawCharts () {
      this.drawPieChart()
    }
  }
}
</script>
