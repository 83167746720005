var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"monitor",attrs:{"id":"monitor"}},[_c('div',{staticClass:"monitor_img listbox",attrs:{"id":"heatmap"}},[_c('img',{ref:"monitorimg",attrs:{"src":require("../../../assets/pmt.png"),"alt":"商场平面图"}}),_vm._l((_vm.listmapall),function(items,indexs){return _c('li',{key:indexs,ref:"monitorlist",refInFor:true,style:({
      left:
        JSON.parse(items.mapLocation).x * _vm.monitorimgwith -
        (_vm.monitorimgheight * 0.06)/2 +
        'px',
      top:
        JSON.parse(items.mapLocation).y * _vm.monitorimgheight -
        (_vm.monitorimgheight * 0.10) +
        'px',
      width: _vm.monitorimgheight * 0.07 + 'px',
      height: _vm.monitorimgheight * 0.07 + 'px',
    }),attrs:{"id":items.id},on:{"mousedown":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.move($event)}}},[_c('span',{staticClass:"deviceid"},[_vm._v(" "+_vm._s(items.name)+" ")]),_c('div',{attrs:{"id":items.id}},[_vm._v(_vm._s(items.num))])])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }