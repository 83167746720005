<template>
<!-- 实时客流 -->
  <div class="box">
    <div class="header">{{ header }}</div>
    <div class="body">
        <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    header: { type: String, required: false },
  },
};
</script>

<style scoped lang="scss">
.box {
  position: relative;

  .header {
    height: 40px;
    line-height: 40px;
    margin: 0 16px;
    color: #dddddd;
    font-weight: bold;
  }

  .body {
    height: calc(100% - 40px);
    color: #fff;
    .boimg{
      width: 300px;
      height: 200px;
      img{
        overflow: hidden;
      }
    }
  }
}
</style>