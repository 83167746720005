<template>
  <!-- 今日客流 -->
  <div class="total">
    <div class="text-wrapper">{{ totalNum }}</div>
    <!-- 现场客流 -->
    <!-- <div class="site">
      <div class="si-title">现场客流</div>
      <div class="si-num">451</div>
    </div> -->
  </div>
</template>
<script>
export default {
  data () {
    return {
      totalNum: '' //今日客流
    }
  },
  mounted() { 
    this.todList()
    let that = this;
    setInterval(() => {
      that.todList();
    }, 10000);
  },
  methods: {
    todList () {
      this.$api.charts
        .today()
        .then((res) => {
           this.totalNum = res.num
        })
        .catch((error) => console.log(error));
    }
  }
}
</script>

<style lang="scss" scoped>
.total {
   display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-items: center;
  .text-wrapper {
  padding: 16px 0;
  width: 100%;
  text-align: center;
  color: #ffffff;
  font-size: 58px;
  display: inline-block;
  letter-spacing: 10px;
}
  // .site{
  //   .si-title{
  //     height: 40px;
  //     line-height: 40px;
  //     margin: 0 16px;
  //     color: #dddddd;
  //     font-weight: bold;
  //   }
  //   .si-num{
  //     padding: 16px 0;
  //     width: 100%;
  //     text-align: center;
  //     color: #ffffff;
  //     font-size: 58px;
  //   }
  // }
}
</style>
