<template>
  <!-- 年龄段分析 -->
  <div class="chart-wrapper" style="width: 100%; height: 100%">
    <div class="chart" ref="chart" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
import echarts from 'echarts'
export default {
  data () {
    return {
      chartPie: ''
    }
  },
  mounted () {
    this.initData()
    this.drawPieChart()
    let that = this;
    setInterval(() => {
       that.initData();
    }, 10000);
  },
  methods: {
    drawPieChart () {
      this.chartPie = echarts.init(this.$refs.chart)
      this.chartPie.setOption({
        //具体点击某一项触发的样式内容
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        //左上侧分类条形符
        legend: {
          orient: 'vertical',
          left: 'left',
          data: []
        },
        //饼状图类型以及数据源
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: ['35%', '60%'],
            center: ['55%', '55%'],
            data: [],
            //设置饼状图扇形区域的样式
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 1)'
              }
            }
          }
        ]
      })
    },
    //动态获取饼状图的数据
    initData () {
      this.$api.charts
        .geTage({})
        .then(res => {
          var getData = res
          //先进行赋值
          for (let i = 0; i < res.length; i++) {
            var obj = new Object()
            obj.name = res[i].age
            obj.value = res[i].num
            getData[i] = obj
          }
          this.chartPie.setOption({
            legend: {
              data: res
            },
            series: [
              {
                data: getData
              }
            ]
          })
        })
        .catch(error => console.log(error))
    },
  }
}
</script>
