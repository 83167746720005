<template>
  <div class="big-screen">
    <div class="big-screen-header">
      <h1>客流数据大屏</h1>

      <span class="time">{{ date }} </span>
    </div>

    <div class="big-screen-body">
      <div class="big-screen-card-wrapper">
        <box
          class="big-screen-card"
          header="今日客流"
          style="flex: 2; width: 450px"
        >
          <total></total>
        </box>
        <box
          class="big-screen-card white"
          header="性别分析"
          style="flex: 1.5; width: 450px"
        >
          <gender-chart></gender-chart>
        </box>

        <box
          class="big-screen-card age"
          header="年龄段分析"
          style="flex: 2; width: 450px"
        >
          <age-chart></age-chart>
        </box>
      </div>
      <div class="big-screen-card-wrapper" style="width:50%;min-width:900px;margin:auto 20px;">
        <box
          class="big-screen-card"
          header="客流监控"
          style="flex: 1; width: 100%;margin:16px auto;background:linear-gradient(45deg, rgb(142 132 95), rgb(82 75 92), rgb(141 181 211));"
        >
          <monitor></monitor>
        </box>
      </div>
      <div class="big-screen-card-wrapper">
        <box
          header="时间段分析"
          class="big-screen-card tiem"
          style="width: 450px; flex: 1"
        >
          <time-chart></time-chart>
        </box>
        <box
          header="实时客流"
          class="big-screen-card bigItem"
          style="width: 450px; flex: 2"
        >
          <Passflow></Passflow>
        </box>
      </div>
    </div>
    <!-- <box header="实时客流" class="big-screen-card bigItem">
      <monitors></monitors>
    </box> -->
  </div>
</template>

<script>
import Total from "./charts/Total.vue";
import Box from "./charts/Box.vue";
import GenderChart from "./charts/GenderChart.vue";
import TimeChart from "./charts/TimeChart.vue";
import AgeChart from "./charts/AgeChart.vue";
import Monitor from "./charts/Monitor";
import Passflow from "./charts/Passflow";
import monitors from "./charts/monitors";

export default {
  components: {
    Total,
    Box,
    GenderChart,
    TimeChart,
    AgeChart,
    Monitor,
    Passflow,
    // monitors,
  },
  data() {
    return {
    date: '',
    }
  },
  mounted() {
			this.getdate();
			this.timer = setInterval(() => {
				this.getdate();
			}, 1000)
	}, 
  methods:{
			getdate() {
				let weeks = [' 星期日 ', ' 星期一 ', ' 星期二 ', ' 星期三 ', ' 星期四 ', ' 星期五 ', ' 星期六 '];
				let time = new Date();
				let year = time.getFullYear();
				let month = time.getMonth() + 1;
				let day = time.getDate();
        let week = weeks[time.getDay()];
        let hour = time.getHours();
        let minutes = time.getMinutes();
        let seconds = time.getSeconds();
				if(minutes<10){
					minutes='0'+minutes
				}
        this.date = year + ' 年 ' + month + ' 月 ' + day + " 日 " +  week +  hour + " 时 " + minutes +" 分 " + seconds + " 秒 ";
			},
    }
    
};
</script>

<style scoped lang="scss">
.big-screen {
  height: 100vh;
  background-color: #232323;
}

.big-screen-header {
  height: 60px;
  line-height: 60px;
  border-bottom: solid 1px #375992;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;

  h1 {
    color: #ffffff;
    font-size: 24px;
    padding: 0;
    margin: 0;
    font-weight: normal;
  }

  .time {
    color: #ffffff;
  }
}

.big-screen-body {
  position: relative;
  height: calc(100vh - 61px);
  display: flex;
  justify-content: space-between;

  .big-screen-card-wrapper {
    height: 100%;
    display: flex;
    flex-flow: column;

    .big-screen-card {
      background-color: #383648;
      border: 0;
      border-radius: 10px;
      margin: 16px;

      .el-card__header {
        border: 0;
      }
    }
    .bigItem {
      background: linear-gradient(45deg,#1392ce,#c779d0,#4bc0c8);
    }
    .white {
      background: linear-gradient(45deg,#d3959b,#568fdf);
    }
    .age{
      background: url("../../assets/age.png");
      background-repeat:no-repeat;
      background-size:100% 100%;
    }
    .tiem{
      background: url("../../assets/teim.png");
      background-repeat:no-repeat;
      background-size:100% 100%;
    }
  }
}

.card-header {
  color: #ffffff;
}
</style>