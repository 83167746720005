<template>
  <!-- 性别分析 -->
  <div class="chart-wrapper" style="width: 100%; height: 100%">
    <div class="chart" ref="genderchart" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
import echarts from "echarts";
export default {
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.initChart();
    let that = this;
    setInterval(() => {
       that.initChart();
    }, 10000);
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs.genderchart);
      this.$api.charts.geTgender().then(res => {
         let getData = res
          //先进行赋值
          for (let i = 0; i < res.length; i++) {
            let obj = new Object()
            obj.name = res[i].genderStr
            obj.value = res[i].num
            getData[i] = obj
          }
      this.chart.setOption({
        //具体点击某一项触发的样式内容
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} '
        },
         //左上侧分类条形符
        legend: {
          orient: 'vertical',
          left: 'left',
          data: res,
           textStyle:{
            color:'#000000',
        }
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: "70%",
            center: ["50%", "50%"],
            data:getData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
        color: [
          //自定义的颜色
          "rgb(41, 121, 255)",
           "rgb(205, 37, 188)",
          //  "rgb(66, 185, 131)",
          // "rgb(254,166,80)",
        ],
      })
      // 使用刚指定的配置项和数据显示图表。
      // this.chart.setOption(options)
    })
    }
  },
};
</script>
